<template>
  <div>
    <MaterialPreview
      :style="{ visibility: pageInited ? 'visible' : 'hidden' }"
      :materialTitle="materialTitle"
      :materialIntro="materialIntro"
      :skillArr="skillArr"
      :contentArr="contentArr"
    />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  import PageLoading from '@/components/PageLoading/index.vue';
  import MaterialPreview from '@/components/MaterialPreview/MaterialPreview.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { generateTaskItem } from '@/views/material/index/task-build/generate-data.js';

  import { getMaterialDetail } from '@/api/material.js'

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const route = useRoute();

  const pageInited = ref(false);
  const materialID = route.query.mid;
  const materialTitle = ref('');
  const materialIntro = ref('');
  const skillArr = ref([]);
  const contentArr = ref([]);

  function initData() {
    if (!materialID) {
      return;
    }
    loading.value = true;
    getMaterialDetail({ 'Material[id]': materialID }).then(({ code, data }) => {
      if (code === SUCCESS && data) {
        pageInited.value = true;
        const { title, introduce, all_skill, chapter } = data;
        materialTitle.value = title || '';
        materialIntro.value = introduce || '';
        if (Array.isArray(all_skill)) {
          skillArr.value = all_skill; // [{ id: xxx, score: xxx, title: xxx }]
        }
        if (Array.isArray(chapter) && chapter.length > 0) {
          contentArr.value = chapter.map(item => generateTaskItem(false, item));
        } else {
          contentArr.value.push(generateTaskItem(true));
        }
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  initData();
</script>